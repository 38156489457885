import React, { FC } from "react";

export const Loader: FC = () => (
  <div className="loader-ellipsis">
    <div />
    <div />
    <div />
    <div />
  </div>
);
