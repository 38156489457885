/* eslint-disable @typescript-eslint/no-explicit-any */
import { Gallery, Photo, Page } from "../models";
import { toSLug } from "../utils/string.utils";

const BASE_API_URL = process.env.REACT_APP_WORPDRESS_API_URL;
const BASE_IMG_URL = `${process.env.REACT_APP_WORPDRESS_BASE_URL}/wp-content/uploads/`;
const DATA_THUMBNAIL_KEY = "post-thumbnail";

interface WordpressService {
  getPages: () => Promise<Array<Page> | []>;
  getPhotosFromGallery: (galleryName: string) => Promise<Gallery | undefined>;
}

const dataToPages = (data: any): Page[] => {
  const pages: Page[] = [];
  data.forEach((datum: any) => {
    pages.push({
      title: datum.title || "",
      name: datum.name || "",
      director: datum.director || "",
      credits: datum.credits || "",
    });
  });

  return pages;
};

const dataToPhoto = (data: any): Photo => ({
  file: data.file ? `${BASE_IMG_URL}${data.file}` : "",
  loaded: false,
  dimensions: {
    width: data.metadata.width,
    height: data.metadata.height,
  },
  title: data.slidedata.post_excerpt || "",
  description: data.metadata.image_meta.caption || "",
  thumbnail: {
    file: data.metadata.sizes[DATA_THUMBNAIL_KEY].file ? `${BASE_IMG_URL}${data.metadata.sizes[DATA_THUMBNAIL_KEY].file}` : "",
    loaded: false,
    dimensions: {
      width: data.metadata.sizes[DATA_THUMBNAIL_KEY].width,
      height: data.metadata.sizes[DATA_THUMBNAIL_KEY].height,
    },
    title: data.slidedata.post_excerpt || "",
    description: data.metadata.image_meta.caption || "",
  },
});

const dataToGallery = (data: any): Gallery | undefined => ({
  id: data.ID || "",
  name: data.title || "",
  director: data.director || "",
  photos: data.photos.map(dataToPhoto) || [],
  credits: data.credits || "",
});

export const wordpressService = (): WordpressService => ({
  getPages: async () => {
    let pages: Page[] = [];
    const url = `${BASE_API_URL}/pages/all`;
    await fetch(url).then((response) => response.json()).then((json) => {
      pages = dataToPages(json);
    });

    return pages;
  },
  getPhotosFromGallery: async (galleryName: string) => {
    let gallery;
    const url = `${BASE_API_URL}/pages/${toSLug(galleryName)}`;
    await fetch(url).then((response) => response.json()).then((json) => {
      gallery = dataToGallery(json);
    });

    return gallery;
  },
});
