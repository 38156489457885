import React, { FC, useMemo, useState } from "react";
import { Gallery as GalleryModel } from "../../models";
import { Slider } from "../slider";

import "./Gallery.component.scss";

interface Props {
  gallery: GalleryModel;
}

const IMG_CLASSES = ["wide", "tall", "big"];

export const Gallery: FC<Props> = ({ gallery }: Props): JSX.Element | null => {
  if (gallery.photos.length === 0) return null;

  const { photos, credits } = gallery;

  const photosLoadedDefault = Array<boolean>(photos.length).fill(false);
  const [photosLoaded, setPhotosLoaded] = useState<Array<boolean>>(
    photosLoadedDefault,
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSliderOpen, setIsSliderOpen] = useState<boolean>(false);
  const [sliderStartIndex, setSliderStartIndex] = useState<number>(0);

  const handleImgLoad = (imgIndex: number) => () => {
    photosLoaded[imgIndex] = true;
    setPhotosLoaded(photosLoaded);
    if (photosLoaded.every((p) => p === true)) {
      setIsLoading(false);
    }
  };

  const handleClick = (index: number) => (evt: React.MouseEvent<HTMLDivElement>) => {
    evt.preventDefault();
    setSliderStartIndex(index);
    setIsSliderOpen(true);
  };

  const handleCloseSlider = () => setIsSliderOpen(false);

  const items = useMemo(() => {
    const result = [];
    let i = 0;
    let className;
    let random;
    while (i < photos.length) {
      className = IMG_CLASSES[Math.floor((Math.random() * 10)) % IMG_CLASSES.length];
      random = Math.random();
      if (random > 0.2) {
        const splitUrl = photos[i].file.split("/");
        if (photos[i].thumbnail) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const thumbnailSplitUrl = photos[i].thumbnail!.file.split("/");
          splitUrl.splice(-1);
          splitUrl.push(thumbnailSplitUrl[thumbnailSplitUrl.length - 1]);
        } else {
          splitUrl.splice(3, 0, "800");
        }
        const src = splitUrl.join("/");
        result.push(
          <div key={src} className={className} onClick={handleClick(i)}>
            <img
              src={src}
              alt=""
              onLoad={handleImgLoad(i)}
            />
            <div className="img__mask" />
          </div>,
        );
        i += 1;
      } else {
        result.push(<div key={random} className={className} />);
      }
    }

    return result;
  }, [photos]);

  return (
    <>
      <div className="gallery">
        {isLoading
          ? <div className="loader-ring"><div /><div /><div /><div /></div> : null}
        <div className="grid-wrapper">{items}</div>
      </div>
      {credits
        ? <div className="gallery__footer">{credits}</div>
        : null}
      {isSliderOpen
        ? (
          <div className="slider-wrapper">
            <Slider photos={photos} startIndex={sliderStartIndex} onClose={handleCloseSlider} />
          </div>
        )
        : null}
    </>
  );
};
