import { createContext } from "react";
import { Page } from "../models";

export interface IApplicationContext {
    pages: Page[];
    setPages: (pages: Page[]) => void;
}

export const ApplicationContext = createContext<IApplicationContext>({
  pages: [],
  setPages: () => {
    throw new Error("Not implemented");
  },
});
