import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { ApplicationContext } from "../../context";
import { Page } from "../../models";

import "./Navigation.component.scss";

/*
export const navigationRoutes = [
  { name: "Germinal", director: "de David Hourrègue" },
  { name: "L'Absente", director: "de Karim Ouaret" },
  { name: "Police de Caractères", director: "de Gabriel Aghion" },
  { name: "Autres Séries TV", director: "" },
  { name: "Films unitaires", director: "" },
];
*/

interface Props {
  onClick: () => void;
}

export const Navigation = ({ onClick }: Props): JSX.Element => {
  const { pages } = useContext(ApplicationContext);
  return (
    <div className="navigation">
      <div className="navigation__content">
        <section className="navigation__content__column">
          <h2 className="navigation__subtitle">Work</h2>
          <hr className="separator" />
          <ul>
            {pages.map((page: Page) => (
              <li key={page.name} className="navigation__link">
                <NavLink to={`/${page.name}`} onClick={onClick}>{page.title} {page.director ? <span className="small">{page.director}</span> : null }</NavLink>
              </li>
            ))}
          </ul>
        </section>
        <section className="navigation__content__column">
          <h2 className="navigation__subtitle">Contact</h2>
          <hr className="separator" />
          <p className="navigation__text">
            Sarah Alcalay
            <br />
            <span className="phone">06 75 07 51 48</span>
            <br />
            <a href="mailto:smalcalay@hotmail.fr">smalcalay@hotmail.fr</a>
          </p>
        </section>
      </div>
    </div>
  );
};
