import React, { PropsWithChildren, useEffect, useState } from "react";
import { AppLoader } from "../components/loader";
import { Page } from "../models";
import { wordpressService } from "../services/wordpress.service";
import { ApplicationContext } from "./Application.context";

// eslint-disable-next-line max-len
export const ApplicationContextProvider = ({ children }: PropsWithChildren<unknown>): JSX.Element => {
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [pages, setPages] = useState<Page[]>([]);

  useEffect(() => {
    setIsLoadingData(true);
    wordpressService().getPages().then((result) => {
      if (result) setPages(result);
    }).finally(() => setIsLoadingData(false));
  }, []);

  return (
    <ApplicationContext.Provider value={{ pages, setPages }}>
      {isLoadingData ? <AppLoader /> : children}
    </ApplicationContext.Provider>
  );
};
