import React from "react";
import { Helmet } from "react-helmet";
import { BackgroundSlider } from "../components/background-slider";
import { Header } from "../components/header/Header.component";

export const Home: React.FC = (): JSX.Element => (
  <>
    <Helmet>
      <meta
        name="description"
        content="Sarah Alcalay - Photographe de plateau"
      />
      <title>Sarah Alcalay - Photographe de plateau</title>
      <link rel="canonical" href="https://www.sarahalcalay.com" />
      <link rel="shortlink" href="https://www.sarahalcalay.com" />
    </Helmet>
    <BackgroundSlider />
    <Header />
  </>
);
