import React, { useEffect, useState } from "react";
import { useLocation, withRouter } from "react-router-dom";
import { Gallery } from "../../models";
import { wordpressService } from "../../services/wordpress.service";
import { Page } from "./Page.component";

const PageContainerBase: React.FC = () => {
  const location = useLocation();
  const [gallery, setGallery] = useState<Gallery | undefined>(undefined);
  const [isLoadError, setIsLoadError] = useState<boolean>(false);
  const errorMsg = "Une erreur est survenue, impossible de charger les photos. Veuillez recharger la page ou réessayer plus tard.";

  useEffect(() => {
    const galleryName = location.pathname.split("/").pop();
    if (galleryName) {
      wordpressService().getPhotosFromGallery(galleryName).then((result) => {
        if (result) setGallery(result);
      }).catch(() => {
        setIsLoadError(true);
      });
    }
  }, [location.pathname]);

  if (isLoadError) return <div className="load-error">{errorMsg}</div>;

  return gallery ? <Page gallery={gallery} /> : null;
};

export const PageContainer = withRouter(PageContainerBase);
