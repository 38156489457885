import classNames from "classnames";
import React, {
  ImgHTMLAttributes, useEffect, useState,
} from "react";
import { useWindowSize } from "../hooks/useWindowSize";

import "./BackgroundImage.component.scss";

export const BG_IMG_MAX_WIDTH = 1920;
export const BG_IMG_MAX_HEIGHT = 1200;
export enum BackgroundImagePosition {
  Top = "TOP",
  Center = "CENTER",
  Bottom = "BOTTOM",
}

interface Props extends ImgHTMLAttributes<HTMLImageElement> {
  position?: BackgroundImagePosition;
  width?: number;
  height?: number;
  fitScreen?: boolean;
}

interface State {
  insetTop: number;
  width: number;
  height: number;
}

export const BackgroundImage = ({
  alt, className, position, fitScreen, ...props
}: Props): JSX.Element => {
  const [state, setState] = useState<State>({
    insetTop: 0, // default case of position === BackgroundImagePosition.Top
    width: props.width || BG_IMG_MAX_WIDTH,
    height: props.height || BG_IMG_MAX_HEIGHT,
  });
  const [windowWidth, windowHeight] = useWindowSize();

  const updateFitScreen = () => {
    const { width, height } = state;
    let { insetTop } = state;
    let newWidth = width;
    let newHeight = height;
    const ratio = !!props.width && !!props.height ? props.width / props.height : 0;
    if (fitScreen) {
      if (windowWidth > windowHeight) { // Fit landscape
        newWidth = windowWidth;
        newHeight = Math.floor(height * (ratio > 0 ? ratio : windowWidth / width));
        if (
          newHeight < windowHeight
          && (Math.floor(width * (windowHeight / height)) > windowWidth)
        ) {
          newHeight = windowHeight;
          newWidth = Math.floor(width * (windowHeight / height));
        }
      }
      if (windowHeight > windowWidth) { // Fit portrait
        newHeight = windowHeight;
        if (ratio > 0) {
          newWidth = Math.floor(width / ratio);
        } else {
          newWidth = Math.floor(ratio > 0 ? width / ratio : width * (windowHeight / height));
        }
      }
    } else {
      newWidth = props.width || BG_IMG_MAX_WIDTH;
      newHeight = props.height || BG_IMG_MAX_HEIGHT;
    }
    if (position) {
      if (position === BackgroundImagePosition.Center) {
        insetTop = Math.floor((windowHeight - newHeight) / 2);
      }
      if (position === BackgroundImagePosition.Bottom) {
        insetTop = windowHeight - newHeight;
      }
      if (insetTop > 0) {
        insetTop = 0;
      }
    }
    setState({ insetTop, width: newWidth, height: newHeight });
  };

  useEffect(() => {
    updateFitScreen();
  }, [windowWidth, windowHeight, position]);

  const { insetTop, width, height } = state;
  return (
    <img
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      alt={alt}
      className={classNames(className, "background-slider__img")}
      style={{ top: `${insetTop}px` }}
      width={`${width}px`}
      height={`${height}px`}
    />
  );
};

BackgroundImage.defaultProps = {
  position: undefined,
  width: undefined,
  height: undefined,
  fitScreen: false,
};
