import classNames from "classnames";
import React, {
  useState, FC, useRef, useEffect,
} from "react";
import { isMobile } from "react-device-detect";
import { Dimensions, Photo } from "../../models";
import { useWindowSize } from "../hooks/useWindowSize";

import "./Slider.component.scss";

const MARGIN_PERCENT = 5;

interface Props {
  photos: Photo[];
  onClose: () => void;
  startIndex?: number;
}

interface State {
  isLoading: boolean;
  isFadingIn: boolean;
  currentIndex: number;
}

export const Slider: FC<Props> = ({
  photos,
  onClose,
  startIndex = 0,
}: Props): JSX.Element | null => {
  if (photos.length === 0) return null;

  const [state, setState] = useState<State>({
    isLoading: true,
    isFadingIn: true,
    currentIndex: startIndex,
  });
  const [windowWidth, windowHeight] = useWindowSize();
  const sliderRef = useRef<HTMLDivElement>(null);
  const touchStartXRef = useRef<number | null>(null);
  const touchEndXRef = useRef<number | null>(null);

  const getDimensions = (photo: Photo): Dimensions => {
    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    let imgWidth = photo.dimensions!.width;
    let imgHeight = photo.dimensions!.height;
    /* eslint-enable @typescript-eslint/no-non-null-assertion */
    const maxWidth = windowWidth - (2 * MARGIN_PERCENT * windowWidth) / 100;
    const maxHeight = windowHeight - (2 * MARGIN_PERCENT * windowHeight) / 100;
    if (imgWidth > maxWidth) {
      const originalWidth = imgWidth;
      imgWidth = maxWidth;
      imgHeight = Math.floor(imgHeight * (imgWidth / originalWidth));
    }
    if (imgHeight > maxHeight) {
      const originalHeight = imgHeight;
      imgHeight = maxHeight;
      imgWidth = Math.floor(imgWidth * (imgHeight / originalHeight));
    }

    return { width: imgWidth, height: imgHeight };
  };

  const handleSlidePrevious = () => {
    const newIndex = state.currentIndex - 1;
    setState({ currentIndex: newIndex, isFadingIn: true, isLoading: true });
  };

  const handleSlideNext = () => {
    const newIndex = state.currentIndex + 1;
    setState({ currentIndex: newIndex, isFadingIn: true, isLoading: true });
  };

  const getIndexPhoto = (index: number) => {
    if (index < 0) {
      return (photos.length + (index % photos.length)) % photos.length;
    }
    return index % photos.length;
  };

  const handleAnimationEnd = () => {
    setState({ ...state, isFadingIn: false });
  };

  const handleClose = () => onClose();

  const handleImgLoad = () => {
    setState({ ...state, isLoading: false, isFadingIn: true });
  };

  const handleTouchX = () => {
    if (touchEndXRef.current && touchStartXRef.current
      && touchEndXRef.current !== touchStartXRef.current) {
      if (touchEndXRef.current < touchStartXRef.current) {
        handleSlideNext();
      } else {
        handleSlidePrevious();
      }
    }
  };

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.addEventListener("touchstart", (evt: TouchEvent) => {
        touchStartXRef.current = evt.changedTouches[0].screenX;
      });

      sliderRef.current.addEventListener("touchend", (evt: TouchEvent) => {
        touchEndXRef.current = evt.changedTouches[0].screenX;
        handleTouchX();
      });
    }
  }, []);

  const photo = photos[getIndexPhoto(state.currentIndex)];
  const dimensions = getDimensions(photo);
  const marginTop = (windowHeight * MARGIN_PERCENT) / 100;
  const title = photo.title ? (<div className="slider__img__title">{photo.title}</div>) : null;

  return (
    <div className="slider__bg">
      <div ref={sliderRef} className="slider">
        <div
          title="Fermer"
          className="slider__img"
          style={{ marginTop: `${marginTop}px` }}
          onClick={handleClose}
        >
          <img
            src={photo.file}
            alt=""
            className={classNames({
              "slider-fade-in": state.isFadingIn,
            })}
            onAnimationEnd={handleAnimationEnd}
            width={`${dimensions.width}px`}
            height={`${dimensions.height}px`}
            onLoad={handleImgLoad}
          />
          {title}
          <div className="slider__img__mask" />
          {state.isLoading
            ? <div className="loader-bg"><div className="loader-ring"><div /><div /><div /><div /></div></div> : null}
        </div>
      </div>
      {!state.isLoading && !isMobile
        ? (
          <>
            <div
              title="Photo précédente"
              className="slider__btn--previous"
              onClick={handleSlidePrevious}
            />
            <div
              title="Photo suivante"
              className="slider__btn--next"
              onClick={handleSlideNext}
            />
          </>
        )
        : null }
    </div>
  );
};

Slider.defaultProps = {
  startIndex: 0,
};
