import React from "react";
import { Helmet } from "react-helmet";
import slugify from "slugify";
import { Gallery as GalleryModel } from "../../models";
import { Gallery } from "../gallery";
import { Header } from "../header";

interface Props {
  gallery: GalleryModel;
}

export const Page: React.FC<Props> = ({ gallery }: Props): JSX.Element => {
  const title = `Sarah Alcalay - Photographe de plateau - ${gallery.name} ${gallery.director}`;
  const href = `https://www.sarahalcalay.com/${slugify(gallery.name, { lower: true })}`;
  return (
    <div className="page">
      <Helmet>
        <meta
          name="description"
          content={title}
        />
        <title>{title}</title>
        <link rel="canonical" href={href} />
        <link rel="shortlink" href={href} />
      </Helmet>
      <Header />
      <Gallery gallery={gallery} />
    </div>
  );
};
