import classNames from "classnames";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Navigation } from "../navigation";

import "./Header.component.scss";

export const Header = (): JSX.Element => {
  const history = useHistory();
  const [isMenuOpened, setIsMenuOpened] = useState<boolean>(false);
  const toggleMenu = () => {
    setIsMenuOpened(!isMenuOpened);
    if (isMenuOpened) {
      history.push("/");
    }
  };
  const handleClick = () => setIsMenuOpened(false);
  const classes = classNames("header", { "menu--opened": isMenuOpened });

  return (
    <>
      <header className={classes} onClick={toggleMenu}>
        <h1 className="title">Sarah ALCALAY</h1>
        <hr className="separator" />
        <h2 className="subtitle">Photog<span className="text-reversed">r</span>aphe de plateau</h2>
      </header>
      {isMenuOpened ? <Navigation onClick={handleClick} /> : null}
    </>
  );
};
