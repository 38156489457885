import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Home } from "./pages/Home";
import { PageContainer } from "./components/page/Page.container";
import { ApplicationContext, ApplicationContextProvider } from "./context";

import "./App.scss";

function App(): JSX.Element {
  return (
    <div className="App">
      <ApplicationContextProvider>
        <BrowserRouter>
          <ApplicationContext.Consumer>
            {({ pages }) => {
              const routes = pages.map((page) => (
                <Route key={page.name} path={`/${page.name}`}>
                  <PageContainer />
                </Route>
              ));
              routes.push(
                <Route key="home" path="/" exact>
                  <Home />
                </Route>,
              );
              return (
                <Switch>{routes}</Switch>);
            }}
          </ApplicationContext.Consumer>
        </BrowserRouter>
      </ApplicationContextProvider>
    </div>
  );
}

export default App;
